import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import CustomTable from "../../Shared/CustomTable";
import { API_URLS } from "../../config/APIUrls";
import axiosInstance from "../../config/axios";

const CoinList = () => {
  const [loading, setloding] = useState(false);
  const [data, setData] = useState([]);

  const CoinListFn = async () => {
    setloding(true);
    try {
      const response = await axiosInstance.get(API_URLS.md_coin_own);
      setData(response?.data?.data || []);
      setloding(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    CoinListFn();
  }, []);


  const tablehead = [
    <span>S.No</span>,
    <span>Customer ID</span>,
    <span>Name</span>,
    <span>Mobile No.</span>,
    <span>Coin Req.</span>,
    <span>Used Coin</span>,
    <span>Opening Balance</span>,
    <span>Closing Balance</span>,
    <span>Date</span>,
  ];

  const tablerow = data?.map((i, index) => {
    return [
      <span>{index + 1}</span>,
      <span>{i?.lo_cust_id}</span>,
      <span>{i?.c_first_name} {i?.c_last_name}</span>,
      <span>{i?.c_mobile}</span>,
      <span>{Number(i?.d_r_coins)?.toFixed(2)}</span>,
      <span>{Number(i?.c_used_coins)?.toFixed(2)}</span>,
      <span>{Number(i?.d_r_opening_bal)?.toFixed(2)}</span>,
      <span>{Number(i?.d_r_closing_bal)?.toFixed(2)}</span>,
      <span>{moment?.utc(i?.d_r_req_date)?.format("DD-MM-YYYY HH:mm:ss")}</span>,
     
    ];
  });

  return (
    <div>
      <CustomTable tablehead={tablehead} tablerow={tablerow} isLoading={loading} />


    </div>
  );
};

export default CoinList;
