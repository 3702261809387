import { Button, MenuItem, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_URLS } from "../../config/APIUrls";
import axiosInstance from "../../config/axios";
import { ipvalidationSchema } from "../../Services/FormValidation";
import CustomCircularProgress from "../../Shared/CustomDialogBox";
import { useNavigate } from "react-router-dom";

const AddipAddress = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const initialValue = {
    c_id: "SelectCustomer",
    domain_url: "",
    domain_ip: "",
  };

  const fk = useFormik({
    initialValues: initialValue,
    validationSchema: ipvalidationSchema,
    enableReinitialize: true,
    onSubmit: () => {
      const reqBody = {
        c_id: fk.values.c_id,
        domain_ip: fk.values.domain_ip,
        domain_url: fk.values.domain_url,
      };

      AddIPFunction(reqBody);
    },
  });

  const AddIPFunction = async (reqBody) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        API_URLS.activate_ip_address,
        reqBody
      );
      toast(response?.data?.msg);
      if ("IP Activated Successfully." === response?.data?.msg) {
        fk.handleReset();
        navigate('/iplist');
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong while adding the IP.");
    } finally {
      setLoading(false);
    }
  };

  const CustomerFn = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(API_URLS.customer);
      setData(response?.data?.data || []);
    } catch (e) {
      console.log(e);
      toast.error("Failed to fetch customers.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    CustomerFn();
  }, []);

  return (
    <>

      <div className="!flex justify-center items-center w-full">
        <div className="p-5 lg:w-1/2 md:w-3/4 w-full bg-white !bg-opacity-30 !rounded-lg">
          <p className="!text-center font-bold !py-4 text-lg">Activate IP Address</p>
          <div className="grid grid-cols-1 gap-[6%] gap-y-6 pt-5">
            <div >
            <p className="font-bold">Select Customer</p>
              <TextField
                select
                id="c_id"
                name="c_id"
                value={fk.values.c_id}
                onChange={fk.handleChange}
                fullWidth

              >
                <MenuItem value="SelectCustomer"> Select Customer</MenuItem>
                {data?.map((item) => (
                  <MenuItem key={item?.c_lo_id} value={item?.c_lo_id}>
                    {item?.c_first_name} {item?.c_last_name} {item?.c_mobile}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <p className="font-bold">Domain IP</p>
              <TextField
                type="text"
                fullWidth
                id="domain_ip"
                name="domain_ip"
                placeholder="192.168.18.14"
                value={fk.values.domain_ip}
                error={fk.touched.domain_ip && Boolean(fk.errors.domain_ip)}
                helperText={fk.touched.domain_ip && fk.errors.domain_ip}
                onChange={fk.handleChange}
              />
            </div>
            <div>
              <p className="font-bold">Domain URL</p>
              <TextField
                fullWidth
                type="url"
                id="domain_url"
                name="domain_url"
                placeholder="https://google.com"
                value={fk.values.domain_url}
                error={fk.touched.domain_url && Boolean(fk.errors.domain_url)}
                helperText={fk.touched.domain_url && fk.errors.domain_url}
                onChange={fk.handleChange}
              />
            </div>
          </div>

          <div className="flex justify-start !mt-5 gap-3">
            <Button
              onClick={() => fk.handleReset()}
              variant="contained"
              className="!bg-[#E74C3C]"
            >
              Clear
            </Button>
            <Button
              onClick={() => fk.handleSubmit()}
              variant="contained"
              className="!bg-[#07BC0C]"
            >
              Submit
            </Button>
          </div>
          <CustomCircularProgress isLoading={loading} />
        </div>
      </div></>
  );
};

export default AddipAddress;
