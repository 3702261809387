import { FilterAlt } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import CustomTable from "../../Shared/CustomTable";
import { API_URLS } from "../../config/APIUrls";
import axiosInstance from "../../config/axios";

const TransactionList = () => {
    const [loading, setloding] = useState(false)
    const [data, setData] = useState([])
    const [total, setTotal] = useState({})
    const initialValue = {
        username: "",
        start_date: "",
        end_date: ""
    }
    const fk = useFormik({
        initialValues: initialValue,
        enableReinitialize: true,
        onSubmit: () => {
            setloding(true)
            const reqBody = {
                username: fk.values.username,
                start_date: fk.values.start_date,
                end_date: fk.values.end_date
            }
            TransactionFn(reqBody)
        }
    })
    const TransactionFn = async (reqBody) => {
        setloding(true)
        try {
            const response = await axiosInstance.post(API_URLS.transactiondetails, reqBody)
            setData(response?.data?.data || [])
            setTotal(response?.data || {})
            setloding(false);
        }
        catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        TransactionFn()
    }, [])

    const tablehead = [
        <span>S.No</span>,
        <span>Account ID</span>,
        <span>UTR No.</span>,
        <span>Email</span>,
        <span>Domain IP</span>,
        <span>Domain URL</span>,
        <span>Coin</span>,
        <span>Opening Bal.</span>,
        <span>Closing Bal.</span>,
        <span>Type</span>,
        <span>Status </span>,
        <span>Description</span>,
        <span>Date/Time</span>,
    ];

    const tablerow = data?.map((i, index) => {
        return [
            <span>{index + 1}</span>,
            <span>{i?.end_user_account_id} </span>,
            <span>{i?.wallet_tr_utr_no}</span>,
            <span>{i?.end_user_email}</span>,
            <span>{i?.do_domain_ip}</span>,
            <span>{i?.do_domain_url}</span>,
            <span>{Number(i?.wallet_tr_amount)?.toFixed(2)}</span>,
            <span>{Number(i?.mngr_open_bal)?.toFixed(2)}</span>,
            <span>{Number(i?.mngr_clos_bal)?.toFixed(2)}</span>,
            <span>{i?.wallet_tr_type === "Dr" ? "Debit" :
                "Credit"}</span>,
            <span>{i?.wallet_tr_status}</span>,
            <span>{i?.wallet_tr_des}</span>,
            <span>
                {moment.utc(i?.created_at)?.format("DD-MM-YYYY HH:mm:ss")}
            </span>,
        ];
    });
    return (
        <div>
            <div className="flex px-2 gap-5 !justify-start py-2">
                <span className="font-bold !text-center">From:</span>
                <TextField
                    size="small"
                    type="date"
                    id="start_date"
                    name="start_date"
                    value={fk.values.start_date}
                    onChange={fk.handleChange}
                />
                <span className="font-bold !text-center">To:</span>
                <TextField
                    size="small"
                    type="date"
                    id="end_date"
                    name="end_date"
                    value={fk.values.end_date}
                    onChange={fk.handleChange}
                />
                <TextField
                    size="small"
                    type="search"
                    id="username"
                    name="username"
                    placeholder="Search by user id"
                    value={fk.values.username}
                    onChange={fk.handleChange}
                />
                <Button
                    onClick={fk.handleSubmit}
                    variant="contained"
                    startIcon={<FilterAlt />}
                >
                    Filter
                </Button>
            </div>

            <CustomTable
                tablehead={tablehead}
                tablerow={tablerow}
                isLoading={loading}
                isTotal={
                    <div className="flex justify-between !pt-2  !px-4 !font-bold">
                        <div> <span>Total Credit : </span><span>{Number(total?.total_cr)?.toFixed(2)}</span></div>
                        <div> <span>Total Debit : </span> <span>{Number(total?.total_dr)?.toFixed(2)}</span></div>
                    </div>
                }
            />

        </div>
    );
};

export default TransactionList;
