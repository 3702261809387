export const API_URLS = {
  login: `/seller/v1/distributor-login`,
  sign: `/seller/v1/reseller-registration`,
  customer: `/seller/v1/reseller-list`,
  update_customer: "/seller/v1/update-customer",
  get_ip_list: "/seller/v1/get-ip-address-list",
  activate_ip_address: "/seller/v1/activate-ip-address",
  change_ip_status: "/seller/v1/change-ip-address-status",
  all_customer: "/seller/v1/get-all-reseller-list-drop-down",
  end_userdetails: "/seller/v1/get-all-end-user-details",
  transactiondetails: "/seller/v1/get-transaction-request-list-end-user",
  dashboard_details: "/seller/v1/get-dashboard-details-distributer",
  get_game_list_for_reseller: "/seller/v1/get-game-list-for-distributer",
  get_jilli_manager_info: "/seller/v1/get-jilli-manager-info",
  customer_status: "/seller/v1/change-status-reseller-distributer",
  add_bank_upi_details: "/seller/v1/add-bank-upi-details",
  list_bank_upi_details: "/seller/v1/get-bank-details",
  md_coin_list: "/seller/v1/m-d-coin-request-list",
  md_coin_add: "/seller/v1/m-d-coin-request",
  coin_add: "/seller/v1/approve-reject-coin-request",
  md_bank_list: "/seller/v1/get-parent-upi-bank-details",
  md_coin_own: "/seller/v1/get-own-tr-coin-list",
  coin_approve_request: `/seller/v1/approve-reject-coin-request`,
  profile_api: `/seller/v1/get-profile-data`,

  //
  get_next_gameid_one_min: `/api/v1/get-bet-one-min-next-id`,
  get_all_player: `/api/v1/getAllPlayer`,
  update_player_status: `/api/v1/updatePlayerStatus`,
  player_registration: `/api/v1/addPlayer`,
  get_username_by_referralid: `/api/v1/getUsernameBy_refId`,
  get_user_data_by_user_id: `/api/v1/getPlayerDataById`,
  update_player_record: `/api/v1/updatePlayerRecord`,
  withdrawl_approved: `/api/v1/withdrawlApproved`,
  winzo_withdrawl: `/api/v1/view-winzo-withdrawl-request-report`,
  wingo_deposit: `/api/v1/view-wingo-main-wallet`,
  withdrawl_reject: `/api/v1/withdrawlReject`,
  withdrawl_approved_function: `/api/v1/withdrawlApprovedFilter`,
  withdrawl_reject_function: `/api/v1/withdrlRejectFilter`,
  add_sub_admin: `/api/v1/addSubAdmin`,
  get_sub_admin_list: `/api/v1/getSubAdmin`,
  get_direct_referral_by_user_id: `/api/v1/direct-referral`,
  get_new_assigned_menu_list: `/api/v1/getAssignSubMenu`,
  get_downline_data_by_id: `/api/v1/downline-referral-data`,
  manually_winning_api: `/api/v1/manually-winning`,
  manually_update_percentage_api: `/api/v1/manually-update-percentage`,
  get_fund_history: `/api/v1/get-fund-history`,
};
