import { Button, MenuItem, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URLS } from "../../config/APIUrls";
import axiosInstance from "../../config/axios";
import CustomCircularProgress from "../../Shared/CustomDialogBox";

const AddCoin = () => {
  const [loding, setloding] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedUPIDetails, setselectedUPIDetails] = useState({});

  const BankUPIListFn = async () => {
    try {
      const response = await axiosInstance.get(API_URLS.md_bank_list);
      setData(response?.data?.data || []);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    BankUPIListFn();
  }, []);

  const initialValue = {
    req_coins: "",
    payment_type: "",
    receipt_image: "",
    bank_upi_id: "",
  };

  const fk = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: () => {
      if(!fk.values.req_coins || !fk.values.payment_type || !fk.values.receipt_image ){
      return toast("Please Enter all Field")
      }
      const reqBody = {
        req_coins: fk.values.req_coins,
        payment_type: fk.values.payment_type === "UPI" ? "2" : "1",
        receipt_image: fk.values.receipt_image,
        bank_upi_id: selectedUPIDetails?.m_d_id,
      };

      AddCoinFunction(reqBody);
    },
  });
  useEffect(() => {
    setselectedUPIDetails(data?.find((i) => i?.m_d_type === fk.values.payment_type) || {})
    // eslint-disable-next-line
  }, [fk.values.payment_type])

  const AddCoinFunction = async (reqBody) => {
    setloding(true);
    try {
      const response = await axiosInstance.post(API_URLS.md_coin_add, reqBody);
      toast(response?.data?.msg);
      if ("Request Accepted Successfully" === response?.data?.msg) {
        fk.handleReset()
        navigate('/coin')
      }
    } catch (e) {
      console.log(e);
    }
    setloding(false);
  };



  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        fk.setFieldValue("receipt_image", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  return (
    <div className="!flex justify-center items-center w-full">
    
      <div className="p-5 w-full rounded-lg">
        <p className="!text-center font-bold !py-4 text-lg">Add Coin</p>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-[6%] gap-y-6 pt-5">
          <div>
            <p className="font-bold">Coin</p>
            <TextField
              type="number"
              fullWidth
              id="req_coins"
              name="req_coins"
              placeholder="Coin"
              value={fk.values.req_coins}
              onChange={fk.handleChange}
            />
          Total payable = {fk.values.req_coins *10}
          </div>
          <div>
            <p className="font-bold">Payment Type</p>
            <TextField
              select
              fullWidth
              type="text"
              id="payment_type"
              name="payment_type"
              value={fk.values.payment_type}
              onChange={fk.handleChange}
            >
              <MenuItem value="Bank">Bank</MenuItem>
              <MenuItem value="UPI">UPI</MenuItem>
            </TextField>
          </div>
          <div>
            <p className="font-bold">Receipt</p>
            <TextField
              fullWidth
              type="file"
              id="receipt_image"
              name="receipt_image"
              onChange={handleImageChange}
            />
          </div>
          <div>

            {fk.values.payment_type === "Bank" ?
              <>
                <div className="!flex gap-2 !font-bold">
                  <p>Acc. Holder Name :</p>
                  <p>{selectedUPIDetails?.m_d_holder_name}</p>
                </div>
                <div className="!flex gap-2 !font-bold">
                  <p>Bank Name :</p>
                  <p>{selectedUPIDetails?.m_d_bank_name}</p>
                </div>
                <div className="!flex gap-2 !font-bold">
                  <p>Account No :</p>
                  <p>{selectedUPIDetails?.m_d_account_no}</p>
                </div>

                <div className="!flex gap-2 !font-bold">
                  <p>IFSC Code :</p>
                  <p>{selectedUPIDetails?.m_d_ifsc_code}</p>
                </div>
                <div className="!flex gap-2 !font-bold">
                  <p>Mobile No. :</p>
                  <p>{selectedUPIDetails?.m_d_mobile_no}</p>
                </div>
              </> : fk.values.payment_type === "UPI" && <div>
                <div className="!flex gap-2 !font-bold">
                  <p>UPI ID :</p>
                  <p>{selectedUPIDetails?.m_d_upi_id}</p>
                </div>
                <div className="!flex gap-2 !font-bold">
                  <p> QR </p>
                  <img src={selectedUPIDetails?.m_d_qr_image} alt="" className="h-64 w-72" />
                </div>
              </div>}

          </div>
        </div>

        <div className="flex justify-end !mt-5 gap-3">
          <Button
            onClick={() => fk.handleReset()}
            variant="contained"
            className="!bg-[#E74C3C]"
          >
            Clear
          </Button>
          <Button
            onClick={() => fk.handleSubmit()}
            variant="contained"
            className="!bg-[#07BC0C]"
          >
            Submit
          </Button>
        </div>
        <CustomCircularProgress isLoading={loding} />
      </div>
    </div>
  );
};

export default AddCoin;
